<template>
  <ul class="navbar-nav mr-auto desktop-only">
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
         aria-haspopup="true" aria-expanded="false">
        {{$t('navbar.dropdown.title')}}
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" :href="path('lighty_share_service_louez')">{{$t('navbar.dropdown.item.1')}}</a>
        <a class="dropdown-item" :href="path('lighty_share_service_rentabilisez')">{{$t('navbar.dropdown.item.2')}}</a>
      </div>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="//blog.lightyshare.com" target="_blank">{{$t('navbar.item.1')}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="//support.lightyshare.com" target="_blank">{{$t('navbar.item.2')}}</a>
    </li>
  </ul>

  <div class="navbar-nav mobile-only">
    <SearchForm :active-suggestion="true"></SearchForm>
  </div>
</template>

<script>
import SearchForm from './SearchForm.vue'

export default {
  name: "PublicMenu",
  components: {
    SearchForm
  }
}
</script>

<style scoped>
.navbar-nav.mobile-only {
  width: 100%;
  display: none;
}

.navbar-nav.mobile-only .search-terms-form {
  width: 100%;
}

@media (max-width: 767px) {
  .navbar-nav.mobile-only {
    display: block;
  }
}
</style>