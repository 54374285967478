<template>
  <ul class="navbar-nav user-menu desktop-only" v-click-outside="closeDropdown">
    <li class="nav-item">
      <a class="nav-link publish-annonce" href="/shop/produits/produit-edit">{{$t('navbar-auth.item.1')}}</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="https://support.lightyshare.com/" target="_blank">{{$t('navbar-auth.item.2')}}</a>
    </li>
    <li class="nav-item dropdown">
      <a v-if="user" class="nav-link dropdown-toggle user-drop-down" href="#" id="navbarDropdown" role="button" @click.prevent="toogleDropdown"
         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
        <span class="user-name">{{ capitalizedPrenom }}</span>
        <img v-if="profileThumb" :src="asset('images-upload/' + $symfony.auth.id + '/profil/' + profileThumb)" alt="" class="user-image" />
        <div v-else class="avatar avatar-sm-32 avatar-color-1">
          <div class="initials">{{ initials }}</div>
        </div>
        <span v-if="countTotalNotification" class="badge-notification">{{countTotalNotification}}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" :class="dropDownClass">
        <a class="dropdown-item" href="/user/messagerie-new">
          {{$t('navbar-auth.dropdown-item.1')}}
          <span v-if="countNewMessages" class="badge-notification">{{countNewMessages}}</span>
        </a>
        <a class="dropdown-item" href="/user/mes-favoris">{{$t('navbar-auth.dropdown-item.2')}}</a>
        <router-link v-if="routerLinks.includes('carts')" class="dropdown-item" to="/user/mes-paniers">
          {{$t('navbar-auth.dropdown-item.3')}}
          <span v-if="countNewPanierSessions" class="badge-notification">{{countNewPanierSessions}}</span>
        </router-link>
        <a v-else class="dropdown-item" href="/user/mes-paniers">
          {{$t('navbar-auth.dropdown-item.3')}}
          <span v-if="countNewPanierSessions" class="badge-notification">{{countNewPanierSessions}}</span>
        </a>


        <router-link v-if="routerLinks.includes('asks')" class="dropdown-item" to="/locataire/reservations/en-attentes">
          {{$t('navbar-auth.dropdown-item.4')}}
          <span v-if="countNewLocations" class="badge-notification">{{countNewLocations}}</span>
        </router-link>
        <a v-else class="dropdown-item" href="/locataire/reservations/en-attentes">
          {{$t('navbar-auth.dropdown-item.4')}}
          <span v-if="countNewLocations" class="badge-notification">{{countNewLocations}}</span>
        </a>

        <div class="dropdown-divider"></div>
        <a class="dropdown-item" :href="'/public/'+$symfony.auth.id+'/show'">{{$t('navbar-auth.dropdown-item.5')}}</a>
        <a v-if="$symfony.auth.boutiqueActive && !routerLinks.includes('dashboard')" class="dropdown-item" href="/shop/dashboard">
          {{$t('navbar-auth.dropdown-item.6')}}
          <span v-if="countNewRentals" class="badge-notification">{{countNewRentals}}</span>
        </a>
        <router-link v-if="$symfony.auth.boutiqueActive && routerLinks.includes('dashboard')" class="dropdown-item" to="/shop/dashboard">
          {{$t('navbar-auth.dropdown-item.6')}}
          <span v-if="countNewRentals" class="badge-notification">{{countNewRentals}}</span>
        </router-link>
        <a class="dropdown-item" href="/account-settings">{{$t('navbar-auth.dropdown-item.7')}}</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item logout" href="/logout">{{$t('navbar-auth.dropdown-item.8')}}</a>
      </div>
    </li>
  </ul>
</template>

<script>
import notifications from "../mixins/notifications";

export default {
  mixins: [notifications],
  name: "AuthNavMenu",
  data () {
    return {
      dropdown: false
    }
  },
  props: {
    routerLinks: {
      type: Array,
      default () {
        return []
      }
    },
    hardLink: {
      type: Boolean,
      default: true
    },
    notifications: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    closeDropdown () {
      this.dropdown = false
    },
    toogleDropdown () {
      this.dropdown = !this.dropdown
    }
  },
  computed: {
    user () {
      if (this.$symfony.auth && this.$symfony.auth !== 'anon.') {
        return this.$symfony.auth
      } else {
        return false
      }
    },
    granted () {
      return this.$symfony.isGranted
    },
    profileThumb () {
      if (this.user && this.user.ImageProfil ) {
        return this.user.ImageProfil.imageName
      } else {
        return false
      }
    },
    dropDownClass () {
      let classes = ''
      if (this.dropdown) {
        classes = 'open'
      }
      return classes
    },
    fetchData () {
      return this.$symfony
    },
    initials () {
      if (this.user) {
        return this.user.prenom[0] + '' + this.user.nom[0]
      } else {
        return ''
      }
    },
    capitalizedPrenom() {
      if (this.$symfony.auth && this.$symfony.auth.prenom) {
        const prenom = this.$symfony.auth.prenom
        return prenom.split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ')
      }
      return ''
    }
  },
  filters: {
    capitalize(value) {
      if (!value) return ''
      return value.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ')
    }
  }
}
</script>

<style scoped>
.dropdown-menu.open {
  display: block;
}
header .dropdown-menu {
  position: absolute;
}
</style>
